<template>
  <div class="jumbo-wrapper">
    <!-- We need overflow-visible so that the shadows from the buttons can flow outside -->
    <div class="container overflow-visible">
      <div class="row">
        <div class="col-lg-5">
          <div v-if="action === 'marpai'">
            <img class="img-fluid header-img" style="width: 375px; max-width: 100%; margin-bottom: 16px;" src="~/assets/img/jumbo/nuvoair-marpai.png" />
          </div>
          <h1>{{ title }}</h1>
          <p class="main-page-p pre-wrap">{{ desc }}</p>
          <!-- <div v-if="action === 'join-nuvoair'">
            <div class="d-flex flex-column text-center mt-9">
              <p class="join-text text-gray-700">Join today</p>
              <div class="d-flex flex-row justify-content-center">
                <a
                  href="#"
                  class="btn btn-primary btn-pill shadow lift mr-3 btn-sm mr-2"
                  @click="openPatientSignup_clickHandler"
                >
                  I am a patient
                </a>
                <a
                  href="#"
                  class="btn btn-white btn-pill shadow lift btn-sm"
                  @click="openDoctorSignup_clickHandler"
                >
                  I am a doctor
                </a>
              </div>
            </div>
          </div> -->
          <div v-if="action && action === 'book'">
            <a href="https://form.jotform.com/230536369560055" class="btn btn-primary btn-pill shadow lift mr-3 btn-sm">
              Learn more
            </a>
            <a href="tel:+18336886247" class="phone-number">
              or call +1 833 688 6247
            </a>
          </div>
          <div v-if="action && action === 'marpai'">
            <a :href="joinUrl" class="btn btn-primary btn-pill shadow lift mr-3 btn-sm">
              Get started
            </a>
            <a href="tel:+18886712022" class="phone-number">
              or call 1 888 671 2022
            </a>
          </div>
          <div v-if="action && action !== 'join-nuvoair' && action !== 'book' && action !== 'health-plans-form' && action !== 'marpai'" >
            <a :href="action" class="btn btn-primary btn-pill shadow lift mr-3 btn-sm">
              {{ actionText }}
            </a>
          </div>
          <div v-if="action && action === 'health-plans-form'">
            <a href="#healthPlansForm" class="btn btn-primary btn-pill shadow lift mr-3 btn-sm">
              {{ actionText }}
            </a>
          </div>
        </div>
        <div class="col-lg-7 d-flex justify-content-center align-items-center">
          <img class="img-fluid header-img" style="margin: 24px auto;" :src="heroImageUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "desc", "action", "actionText", "imageName", "imageUrl", "joinUrl"],
  computed: {
    heroImageUrl() {
      return this.imageName ? `/img/jumbo/${this.imageName}` : this.imageUrl;
    },
  },
  methods: {
    openDoctorSignup_clickHandler() {
      this.$store.commit("modals/setShowDoctorSignupModal", true);
    },
    openPatientSignup_clickHandler() {
      this.$store.commit("modals/setShowPatientSignupModal", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.jumbo-wrapper {
  padding: 32px 0 0;
  .row {
    align-items: center;
  }
}
.join-text {
  font-size: 16px;
  font-weight: 300;
}

.phone-number {
  font-size: 16px;
  font-weight: 400;
  color: #506690;
  margin-top: 8px;
  text-decoration: underline;
}

.zocdoc-widget {
  background-color: #0073E6;
  position: fixed;
  right: 24px;
  bottom: 24px;
  //transform: translateX(calc(100% - 50px));
  z-index: 1000;
  transition: all 0.25s ease-in-out;
  &.hidden {
    visibility: hidden;
  }
  &:hover {
    //transform: translateX(0);
  }
}

.zocdoc-widget-button-custom {
  min-width: 100px;
  position: relative;
  display: table;
  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      opacity: 0;
      z-index: 1005;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }

  }
}
.zocdoc-widget-inner-custom {
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 70px;
  border-radius: 50px;
  background-color: #0073E6;
  .zocdoc-widget-inner-icon {
    padding: 0 12px 0 14px;
    font-size: 24px;
    border-right: 2px solid #00234b;
    color: #00234b;
  }
  .zocdoc-widget-inner-text {
    font-size: 14px;
    color: #fff;
  }
}

.header-img {
  max-width: 512px;
  height: auto;
}


@media (max-width: 992px) {
  .jumbo-wrapper {
    padding: 0;
    .row {
      flex-direction: column-reverse !important;
    }
  }
  .btn {
    font-size: 14px;
  }
  .header-img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 400px) {
  .phone-number {
    font-size: 12px;
  }
}
@media screen and (max-width: 320px) {
  .btn-pill {
    padding-left: 21px !important;
    padding-right: 21px !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 280px) {
  .btn-pill[data-v-248dfc9c] {
    padding-left: 19px !important;
    padding-right: 19px !important;
    font-size: 10px !important;
  }
}
</style>
