export async function loadGraphQLHelper({ app, query, variables }) {
  let result;
  try {
    result = await app.apolloProvider.defaultClient.query({ query, variables });
    return result.data;
  } catch (error) {
    console.error(error);
    if (error.networkError && error.networkError.result && error.networkError.result.errors) {
      console.error(error.networkError.result.errors);
    }
    return undefined;
  }
};
