<template>
  <section class="partners">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10 text-center">
          <h2>{{title}}</h2>
          <p class="main-page-p">
            {{description}}
          </p>
        </div>
        <div v-if="partners.length" class="col-lg-9 col-md-12">
          <div class="partners-container">
            <div v-for="partner in partners" :key="partner.id" class="partner-item">
              <img :src="imageToSize(partner.logo, 'small')" :alt="partner.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import './partners.scss';
import { imageToSize } from '~/util/imageUtil';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    partners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageToSize,
    };
  },
};
</script>
