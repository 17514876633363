<template>
  <div class="join-nuvoair-section pb-9">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-7 col-lg-5">
          <h2>Learn More <span class="highlight-text">Today</span></h2>
          <p class="main-page-p">
            We partner with the NHS to solve problems with our remote monitoring solutions and additional clinical capacity. Contact our team today to learn more.
          </p>
          <div class="text-md-left mt-9 modal-launch-buttons">
            <!-- <a href="javascript:;"
               class="btn btn-primary btn-pill shadow lift mr-3 btn-sm"
               @click.prevent="openPatientSignup_clickHandler">
              I am a patient
            </a> -->
<!--            <a href="javascript:;"-->
<!--               class="btn btn-white btn-pill shadow lift btn-sm"-->
<!--               @click.prevent="openDoctorSignup_clickHandler">-->
<!--              Learn More-->
<!--            </a>-->
            <a href="mailto:sales@nuvoair.com"
               class="btn btn-white btn-pill shadow lift btn-sm">
              Contact Us
            </a>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-7">
          <img class="img-fluid"
               src="../assets/img/joinNuvoAirSection/joinNuvoAir-img.jpg"
               alt="Screenshots of NuvoAir Home app and NuvoAir Clinician Portal">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './joinNuvoAirSection.scss';

export default {
  methods: {
    openDoctorSignup_clickHandler() {
      this.$store.commit('modals/setShowDoctorSignupModal', true);
    },
    openPatientSignup_clickHandler() {
      this.$store.commit('modals/setShowPatientSignupModal', true);
    },
  },
};
</script>
