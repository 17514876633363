<template>
  <div class="blog-card relative">
    <nuxt-link class="blog-card-link" :to="`/blog/${blogPost.slug}`" :aria-label="blogPost.title">
      <div class="card mb-6 shadow-light-lg lift lift-lg">
        <img v-if="imageUrl" class="img-fluid card-img-top" :src="imageUrl" alt="" />
        <div class="position-relative">
          <shape placement="bottom" shape="curve" type="1" color="white"></shape>
        </div>
        <div class="card-body">
          <div class="card-tags-wrapper">
            <span v-for="tag in blogPost.tags" :key="tag.id" class="badge rounded-pill bg-secondary">{{ tag.name }}</span>
          </div>
          <h3>
            {{ blogPost.title }}
          </h3>
        </div>
        <a class="card-meta" href="#!">
          <hr class="card-meta-divider" />
          <div class="avatar avatar-sm me-2">
            <img
              v-if="blogPost.authors.length > 0 && blogPost.authors[0].image"
              :src="blogPost.authors[0].image.url"
              alt="..."
              class="avatar-img rounded-circle"
            />
          </div>
          <h6 v-if="blogPost.authors.length > 0" class="text-uppercase text-gray-700 me-2 mb-0">
            {{ blogPost.authors[0].first_name }}
            {{ blogPost.authors[0].last_name }}
          </h6>
          <p class="h6 text-uppercase text-gray-700 mb-0 ms-auto">
            <time :datetime="blogPost.publish_date">{{ publishedDate }}</time>
          </p>
        </a>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import Shape from '../ui-kit/shape.vue';
import GlobalMixin from '~/mixins/GlobalMixin';
import { imageToSize } from '~/util/imageUtil';

export default {
  components: {
    Shape,
  },
  mixins: [GlobalMixin],
  props: ['blogPost'],
  computed: {
    publishedDate() {
      return this.formatDateFromString(this.blogPost.publish_date, 'N/A');
    },
    imageUrl() {
      if (this.blogPost.thumbnail) {
        return imageToSize(this.blogPost.thumbnail);
      }
      return this.blogPost.image_header ? imageToSize(this.blogPost.image_header) : '';
    },
  },
};
</script>
