<template>
  <div class="home-enterprise">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2>
            Supporting Diagnosis and Ongoing Management
          </h2>
          <p class="main-page-p">
            We offer home monitoring support at different points of care to expand your clinical capacity and improve the patient experience.
          </p>
          <br />
          <div class="row align-start">
            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  <strong style="display: contents">12-Week Asthma Assessment</strong><br/>
                   We help you accelerate time to accurate diagnoses and optimal treatment for people with uncontrolled symptoms. Our respiratory physiologists engage and coach patients at home to ensure quality controlled data is collected. Regular updates and a final assessment report help you to make timely, informed decisions.
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  <strong style="display: contents">Home Monitoring for Severe Asthma</strong><br/>
                  We remotely monitor those diagnosed with severe asthma to ensure appropriate response to biologic therapy and ongoing asthma control, while empowering your patients to better self-manage their condition.
                </p>
              </div>
            </div>

            
          </div>
        </div>
        <div class="col-lg-6">
          <img
            class="jarallax img-fluid home-enterprise-image"
            data-jarallax
            data-speed=".8"
            src="../../assets/img/home/home-enterprise-img.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixin from "~/mixins/GlobalMixin";
export default {
  mixins: [GlobalMixin],
  computed: {
    visitorLocation() {
      console.log(this.$store.state.global.visitorLocation);
      return this.$store.state.global.visitorLocation;
    }
  },
  watch: {
    "$store.state.global"(newVal, oldVal) {
      console.log(newVal);
      this.visitorLocation = newVal.visitorLocation;
    }
  }
};
</script>
<style lang="scss" scoped>
.home-enterprise {
  padding: 64px 16px 16px 16px;

  .row {
    align-items: center;
  }
}

.align-start {
  align-items: start !important;
}

.custom-enterprise-badge {
  background-color: rgba(0, 115, 230, 0.1);
  color: #0073e6;
  padding: 6px 24px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 16px;
}

.enterprise-bullet-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 161.54%;
  color: #0073e6;
  padding-left: 8px;
}

.enterprise-bullet {
  display: flex;
  span {
    display: inline-flex;
    position: relative;
    top: 4px;
  }
  p {
    display: inline-flex;
  }
}
@media (max-width: 992px) {
  .home-enterprise-image {
    max-width: 512px;
    height: auto;
    display: block;
    margin: 50px auto;
  }
}
@media (max-width: 767px) {
  .home-enterprise {
    padding: 32px 0;
  }
  .home-enterprise-image {
    max-width: 100%;
    width: 375px;
    height: auto;
    display: block;
    margin: 32px auto;
  }
}
</style>
